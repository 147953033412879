<template>
  <div class="introb1">
    <div class="introb1__wrapper">
      <div class="introb1__content">
        <h1 class="introb1__h1 h1 h1--white">
          Bienvenue in der Brasserie Thi!
        </h1>
        <div class="introb1__boxbutton">
          <router-link :to="prefixPath('reservieren')" class="introb1__a">
            <AppButton class="introb1__btn">Reservieren</AppButton>
          </router-link>
        </div>
        <div class="introb1__logo">
          <TheLogo />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheLogo from "@/components/ui/logo/TheLogo.vue";
import AppButton from "../../ui/buttons/AppButton.vue";

export default {
  components: { TheLogo, AppButton },

  setup() {
    const publicPath = process.env.BASE_URL;
    function prefixPath(el) {
      return publicPath + el;
    }
    return {
      prefixPath,
    };
  },
};
</script>

<style lang="scss">
.introb1__a {
  display: flex;
  align-content: center;
}
</style>
